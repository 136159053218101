import {useMemo} from 'react';
import District, {
  CompetitionsUrlTemplate,
  MainUrlTemplate,
  MarketplaceUrlTemplate,
  MatchesCancelledUrlTemplate,
  MatchesLiveUrlTemplate,
  MatchesUrlTemplate,
  TransfersUrlTemplate,
} from 'app/models/District';
import useEarlyAccessFeatureSelector from 'app/hooks/useEarlyAccessFeatureSelector';

interface DistrictUrls {
  districtMainUrl: MainUrlTemplate;
  districtMatchesUrl: MatchesUrlTemplate;
  districtCompetitionsUrl: CompetitionsUrlTemplate;
  districtMarketplaceUrl: MarketplaceUrlTemplate;
  districtTransfersUrl: TransfersUrlTemplate;
  districtMatchesLiveUrl: MatchesLiveUrlTemplate;
  districtMatchesCancelledUrl: MatchesCancelledUrlTemplate;
}

export default function useDistrictUrls(slug: string): DistrictUrls {
  const regionIndexV2Status = useEarlyAccessFeatureSelector('feat_district_main_page');
  const districtModel = useMemo(() => new District(slug, regionIndexV2Status), [slug, regionIndexV2Status]);

  return useMemo(() => {
    return {
      districtMainUrl: districtModel.getMainUrl(),
      districtMatchesUrl: districtModel.getMatchesUrl(),
      districtCompetitionsUrl: districtModel.getCompetitionsUrl(),
      districtMarketplaceUrl: districtModel.getMarketplaceUrl(),
      districtTransfersUrl: districtModel.getTransfersUrl(),
      districtMatchesLiveUrl: districtModel.getMatchesLiveUrl(),
      districtMatchesCancelledUrl: districtModel.getMatchesCancelledUrl(),
    };
  }, [districtModel]);
}
