import {onINP, MetricWithAttribution} from 'web-vitals/attribution';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';

type EventParams = {
  value: number;
  metric_id: string;
  metric_value: number;
  metric_delta: number;
  metric_rating: string;
  fupa_tech_metric: string;
  debug_target: string | undefined;
};

const reportWebVitals = (onPerfEntry: (metric: MetricWithAttribution) => void = sendToAnalytics) => {
  onINP(onPerfEntry);
};

const sendToAnalytics = (metric: MetricWithAttribution) => {
  const {name, rating, attribution, id, value, delta, entries} = metric;

  if (rating === 'good') {
    return;
  }
  // use `id` and `delta` to aggregate events with same id
  const ga4EventParams: EventParams = {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    metric_rating: rating,
    fupa_tech_metric: 'web_vitals',
    debug_target: undefined,
  };

  if (name === 'INP') {
    if (!attribution.interactionTarget) {
      const fallbackTarget = entries.find(entry => !!entry.target);
      if (fallbackTarget) {
        ga4EventParams.debug_target = getSelector(fallbackTarget.target);
        return;
      }
    }
    ga4EventParams.debug_target = attribution.interactionTarget;
  }

  sendEventToGA4(name, ga4EventParams);
};

const getName = (node: Node) => {
  const name = node.nodeName;
  return node.nodeType === 1 ? name.toLowerCase() : name.toUpperCase().replace(/^#/, '');
};

const NODE_TYPE_DOCUMENT = 9;

/**
 * Get the selector of a node from https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/getSelector.ts#L24
 * @param node
 */
const getSelector = (node: Node | null | undefined) => {
  let sel = '';

  try {
    while (node && node.nodeType !== NODE_TYPE_DOCUMENT) {
      const el: Element = node as Element;
      const part = el.id
        ? '#' + el.id
        : getName(el) +
          (el.classList && el.classList.value && el.classList.value.trim() && el.classList.value.trim().length
            ? '.' + el.classList.value.trim().replace(/\s+/g, '.')
            : '');
      if (sel.length + part.length > 99) return sel || part;
      sel = sel ? part + '>' + sel : part;
      if (el.id) break;
      node = el.parentNode;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
};

export {reportWebVitals};
