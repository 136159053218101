import {useLocalStorage} from 'app/hooks/useLocalStorage';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import {useCallback, useRef} from 'react';
import WebStorageManager from 'app/services/web-storage/WebStorageManager';

export const DISABLE_ADS_KEY = 'disableAds';

export default function useDisableAds(): [boolean, (value: boolean) => void] {
  const localStorageManager = useRef<WebStorageManager>(LocalStorageManager.getInstance());
  const disableAds = useLocalStorage(DISABLE_ADS_KEY, process.env.ENVIRONMENT === 'dev');

  const setDisableAds = useCallback(
    (value: boolean) => {
      localStorageManager.current.setItem(DISABLE_ADS_KEY, value);
    },
    [localStorageManager.current]
  );

  return [disableAds, setDisableAds];
}
