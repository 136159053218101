import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'app/reducers/rootReducer';
import {createInitialSessionData} from 'app/reducers/sessionReducer';
import reduxCookiesMiddleware, {getStateFromCookies} from 'redux-cookies-middleware';
import {createInitialUserData} from 'app/reducers/currentUserReducer';
import {partnerInitData} from 'app/reducers/partnerReducer';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {createSsrLocationData} from 'app/reducers/ssrLocationReducer';
import {getElementFromLocalStorage, setElementToLocalStorage} from 'app/helpers/webStorageUtility';
import {featureFlags} from 'app/featureFlags/featureFlags';
import {createInitialEarlyAccessData} from 'app/reducers/earlyAccessReducer';
import is from '@sindresorhus/is';

const defaultCookieExpiryInDays = 365;
const DAY_IN_MS = 86400000;

const generateEarlyAccessFeaturePaths = earlyAccessFeatures => {
  const paths = {};
  // We only support one early access feature at a time
  const feature = earlyAccessFeatures[0];
  if (feature) {
    paths[`earlyAccessFeatures.${feature.slug}`] = {name: 'fupaEAF', expiry: feature.availableUntil};
  }
  return paths;
};

const paths = {
  'session.district.slug': {name: 'selectedDistrictSlug'},
  'currentUser.ppid': {name: 'fupaPPID', expiry: 180},
  ...generateEarlyAccessFeaturePaths(featureFlags),
};

let composeEnhancers = compose;

if (windowIsDefined && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25});
}

const getExpiryDate = cookieExpiry => {
  if (cookieExpiry instanceof Date) {
    return cookieExpiry;
  }
  if (is.number(cookieExpiry)) {
    return new Date(Date.now() + cookieExpiry * DAY_IN_MS);
  }

  return new Date(Date.now() + defaultCookieExpiryInDays * DAY_IN_MS);
};

const customSetCookie = (res, name, value, expiry = defaultCookieExpiryInDays) => {
  const cookieToSet = Object.values(paths).find(path => path.name === name);
  const expiryDate = getExpiryDate(cookieToSet?.expiry ?? expiry);

  if (res) {
    return res.cookie(name, value, {expires: expiryDate, sameSite: 'lax'});
  } else {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; path=/; expires=${expiryDate.toUTCString()}; samesite=lax;`;
  }
};

function getCookieValueByName(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=%22([^;]+)%22'));
  return match ? match[2] : '';
}

function isCookieSet(name) {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
}

function removeCookie(name, domain, path) {
  if (isCookieSet(name)) {
    document.cookie =
      name +
      '=;path=' +
      (path ? path : '/') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

const preprocessReduxData = reduxData => {
  const selectedDistrictCookie = getCookieValueByName('selectedDistrictSlug');
  if (selectedDistrictCookie) {
    const reduxCopy = {...reduxData};
    const currentSlug = reduxCopy?.session?.district?.slug;
    if (currentSlug && currentSlug !== selectedDistrictCookie) {
      reduxCopy.session.district = {slug: selectedDistrictCookie};
      reduxCopy.partner = partnerInitData;
    }
    const currentDefaultUrls = reduxCopy?.navBar?.defaultUrl;
    if (currentDefaultUrls) {
      const newDefaultUrls = Object.keys(currentDefaultUrls).map(key => {
        const url = currentDefaultUrls[key];
        const urlParts = url.split('/');
        const copyParts = [...urlParts];
        if (copyParts.length > 2) {
          if (copyParts[2] !== selectedDistrictCookie) {
            copyParts[2] = selectedDistrictCookie;
          }
        }
        return copyParts.join('/');
      });
      reduxCopy.navBar.defaultUrl = Object.assign({}, newDefaultUrls);
    }
    return reduxCopy;
  } else {
    if (reduxData?.session?.district?.slug) {
      customSetCookie(null, 'selectedDistrictSlug', `"${reduxData.session.district.slug}"`);
    }
    return reduxData;
  }
};

const preloadStateFromLocalStorage = key => {
  let storageValue = getElementFromLocalStorage(key);
  if (!storageValue) {
    storageValue = getCookieValueByName(key);
    removeCookie(key, window.location.hostname);
    if (storageValue) setElementToLocalStorage(key, storageValue);
  }
  return storageValue;
};

const makeStore = (url, req, res) => {
  // reads a cookie from the express request object / writes a cookie from the express request object
  let getCookie;
  const options = {
    setCookie: (name, value, expiry) => customSetCookie(res, name, value, expiry),
  };
  let selectedDistrictSlug = null;
  if (req && res) {
    // Apply Cookie Simplification: old cookie (selectedDistrict - district-object), new cookie (selectedDistrictSlug - district-slug)
    // Fill initial session storage with old cookie's district-slug, although old cookie isn't read anymore by redux-cookies-middleware
    // Old Cookie ist deleted in staticRedirects.js
    const selectedDistrict = req.cookies?.['selectedDistrict'];
    if (selectedDistrict) {
      try {
        selectedDistrictSlug = JSON.parse(selectedDistrict).slug;
      } catch (error) {
        selectedDistrictSlug = null;
      }
    }
    getCookie = name => (name in req.cookies ? req.cookies[name] : null);
  }

  let preloadedState = {
    session: createInitialSessionData(selectedDistrictSlug, url),
    currentUser: createInitialUserData(), // need to create initial data, to set state from cookies
    ssrLocation: createSsrLocationData(url),
    earlyAccessFeatures: createInitialEarlyAccessData(),
  };
  if (windowIsDefined && window.REDUX_DATA) {
    preloadedState = preprocessReduxData(window.REDUX_DATA);
    const notificationPermission = preloadStateFromLocalStorage('notification-permission');
    const notificationNoSupportBannerStorage = preloadStateFromLocalStorage('notification-no-support-banner');
    const notificationNoSupportBanner = notificationNoSupportBannerStorage
      ? Number(notificationNoSupportBannerStorage)
      : 0;
    preloadedState.currentUser = {
      ...preloadedState.currentUser,
      notificationPermission,
      notificationNoSupportBanner,
    };
  }
  const preloadedStateFromCookies = getStateFromCookies(preloadedState, paths, getCookie);

  // ⚠️ Do not use the Sentry redux enhancer middleware -> this will create massive performance problems
  //    Before considering again be sure that the redux store is very small!!
  return createStore(
    rootReducer(),
    preloadedStateFromCookies,
    composeEnhancers(applyMiddleware(thunk, reduxCookiesMiddleware(paths, options)))
  );
};

export default makeStore;
