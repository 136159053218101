import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {lazyLoad} from 'app/components/ads/lazyLoadHelpers';
import useDisableAds from 'app/hooks/useDisableAds';

const Slot = styled.div`
  display: ${props => (props.id === 'ad-Sticky_Mobile_Banner' ? 'block' : 'flex')};
  align-items: inherit;
  justify-content: inherit;
  width: inherit;
  height: inherit;
  min-height: inherit;
  max-height: inherit;
  ${props => props.id === 'ad-Sticky_Mobile_Banner' && 'text-align: center;'};
`;

// Listen for AdsReady-Flag (QM)
const selectAdsReady = createSelector(
  state => state.session,
  session => session.adsReady
);

const destroyOldSlotOnChange = adUnitCode => {
  const adSlotsOnPage = window.googletag.pubads().getSlots();
  if (adSlotsOnPage) {
    const oldSlot = adSlotsOnPage.find(slot => {
      const path = slot.getAdUnitPath();
      if (path === adUnitCode) {
        return slot;
      }
    });
    //  Destroy old slot if existing after layout change or route change
    if (oldSlot) {
      console.log('### Debug-Info Web Interstitial Destroy old slot:', oldSlot?.getSlotElementId());
      window.googletag.destroySlots([oldSlot]);
    }
  }
};

const callWebInterstital = adUnitCode => {
  window.googletag.cmd.push(() => {
    const slot = window.googletag.defineOutOfPageSlot(adUnitCode, window.googletag.enums.OutOfPageFormat.INTERSTITIAL);
    if (slot) {
      slot.addService(window.googletag.pubads());
      window.refreshBids([], adUnitCode);
    }
  });
};

const defineAndDisplaySlot = (adUnitCode, sizes, id, checkOldSlot, prebidTimeout) => {
  // check for existing slots by layout change trigger
  window.googletag.cmd.push(() => {
    if (checkOldSlot) {
      // Destroy and re-define adSlot on layoutChange (AdUnit placed for both templates, e.g. Content_1)
      destroyOldSlotOnChange(adUnitCode);
    }
    const slot = window.googletag.defineSlot(adUnitCode, sizes, id);
    slot.addService(window.googletag.pubads());

    window.googletag.display(id);

    // Prop 'sizes' is needed for test purposes to enable amazon bidder
    // Amazon bidder does not work with prebid
    // standalone solution is implemented and therefore sizes need to be injected
    window.refreshBids([id], adUnitCode, sizes, prebidTimeout);
  });
};

const getPrebidTimeout = id => {
  const DESKTOP_TIMEOUT = 1000;
  const SSR_TIMEOUT = 1000;
  const CSR_DEFAULT_TIMEOUT = 900;
  const RECT_TIMEOUT = 1200;

  const adsWithCustomTimeout = ['ad-Rectangle_1', 'ad-Rectangle_2'];

  const isDesktop = useIsDesktop();
  const ssrRendered = useSelector(selectSsrRendered);

  let prebidTimeout = ssrRendered ? SSR_TIMEOUT : CSR_DEFAULT_TIMEOUT;

  if (adsWithCustomTimeout.includes(id)) {
    prebidTimeout = RECT_TIMEOUT;
  }

  return isDesktop ? DESKTOP_TIMEOUT : prebidTimeout;
};
// AdCore: Component to define, display and refresh AdSlots with given configuration
// only triggered in CSR
const AdCore = ({id, sizes, adUnitCode, adLayout}) => {
  const prebidTimeout = getPrebidTimeout(id);

  // Hook to define slots on mount
  useMountAdSlot(adUnitCode, sizes, id, prebidTimeout);

  // Hook to destroy and optional redefine on layout change
  useUpdateAdSlot(adLayout, adUnitCode, sizes, id, prebidTimeout);
  return <Slot id={id} />;
};

const useMountAdSlot = (adUnitCode, sizes, id, prebidTimeout) => {
  const adsReady = useSelector(selectAdsReady);
  const [disableAds] = useDisableAds();

  const isDesktop = useIsDesktop(id);
  useEffect(() => {
    if (adsReady && !disableAds) {
      // use setTimeout to execute lazyLoad after scrollY has been restored on back navigation
      setTimeout(
        () => lazyLoad(id, () => defineAndDisplaySlot(adUnitCode, sizes, id, false, prebidTimeout), isDesktop),
        0
      );
    }
  }, [adsReady, disableAds]);
};

const useUpdateAdSlot = (adLayout, adUnitCode, sizes, id, prebidTimeout) => {
  const isDesktop = useIsDesktop(id);
  const [disableAds] = useDisableAds();

  const [initResizeHook, setInitResizeHook] = useState(true);

  const getLayoutTrigger = id => {
    // returns layout value as trigger to redefine adUnits shared between templates (e.g. Content_1) or destroy adUnits mobile only (e.g. Sticky_Mobile_Banner)
    const mobileOnly = ['ad-Sticky_Mobile_Banner', 'ad-Mobile_Banner'];
    const desktopOnly = ['ad-Superbanner', 'ad-Superbanner_2', 'ad-Superbanner_3', 'ad-Skyscraper', 'ad-Skyscraper_2'];
    const isDesktopLayoutWithAds = (isDesktop && !adLayout) || (isDesktop && adLayout === 'desktop');
    const isMobileLayoutWithAds = (!isDesktop && !adLayout) || (!isDesktop && adLayout === 'mobile');

    // Set default of layoutTrigger for shared adUnits like to true to execute hook on every update of isDesktop
    // Trigger redefine for shared adUnits (Content_1)
    let layoutTrigger = true;
    if (mobileOnly.includes(id)) {
      // Trigger redefine for mobile only adUnits (Outstream)
      layoutTrigger = isMobileLayoutWithAds;
    } else if (desktopOnly.includes(id)) {
      // Trigger redefine for desktop only adUnits (Superbanner)
      layoutTrigger = isDesktopLayoutWithAds;
    }

    return layoutTrigger;
  };

  useEffect(() => {
    const layoutChange = getLayoutTrigger(id);
    if (!initResizeHook && layoutChange && !disableAds) {
      // use setTimeout to execute lazyLoad after scrollY has been restored on back navigation
      // check for existing slots by layout change trigger
      setTimeout(
        () => lazyLoad(id, () => defineAndDisplaySlot(adUnitCode, sizes, id, true, prebidTimeout), isDesktop),
        0
      );
    }
    setInitResizeHook(false);
    return () => {
      window.googletag.cmd.push(() => {
        // Destroy adSlot on layoutChange (AdUnit placed for only one template, e.g. Skyscraper)
        // Destroy adSlot also on unmount of component like route change (global destroySlots() is not possible due to web interstitial)
        destroyOldSlotOnChange(adUnitCode);
      });
    };
  }, [isDesktop]);
};

export {AdCore, selectAdsReady, callWebInterstital};
