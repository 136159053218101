import ExpFeatureWebInterstitial from 'app/models/experimentalFeature/ExpFeatureWebInterstitial';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import ExperimentalFeatureInterface from 'app/models/experimentalFeature/ExperimentalFeatureInterface';
import StorageInterface from 'app/types/StorageInterface';
import WebStorageAdapter from 'app/services/web-storage/WebStorageAdapter';

type Subscriber = {feature: string; callback: () => void};

export default class ExperimentalFeatureManager {
  public static readonly availableFeatures: ExperimentalFeatureInterface[] = [ExpFeatureWebInterstitial.prototype];
  private static instance: ExperimentalFeatureManager;
  private readonly enabledFeatures: string[] = [];
  private readonly storage: StorageInterface;
  private readonly subscribers: Subscriber[] = [];

  private constructor(storage: StorageInterface) {
    this.storage = storage;
    this.enabledFeatures = this.loadFeatures();
  }

  public static getInstance(storage?: StorageInterface): ExperimentalFeatureManager {
    if (!ExperimentalFeatureManager.instance) {
      ExperimentalFeatureManager.instance = new ExperimentalFeatureManager(
        storage ?? new WebStorageAdapter(LocalStorageManager.getInstance())
      );
    }
    return ExperimentalFeatureManager.instance;
  }

  public isEnabled(feature: ExperimentalFeatureInterface): boolean {
    const featureSlug = feature.getSlug();
    return this.enabledFeatures.includes(featureSlug);
  }

  public enable(feature: ExperimentalFeatureInterface): void {
    const featureSlug = feature.getSlug();
    this.enabledFeatures.push(featureSlug);
    this.saveFeatures();
    this.callSubscribers(feature);
  }

  public disable(feature: ExperimentalFeatureInterface): void {
    const featureSlug = feature.getSlug();
    this.enabledFeatures.splice(this.enabledFeatures.indexOf(featureSlug), 1);
    this.saveFeatures();
    this.callSubscribers(feature);
  }

  private callSubscribers(feature: ExperimentalFeatureInterface): void {
    const featureSlug = feature.getSlug();
    this.subscribers.forEach(subscriber => {
      if (subscriber.feature === featureSlug) {
        subscriber.callback();
      }
    });
  }

  public subscribe(feature: ExperimentalFeatureInterface, callback: () => void): void {
    const featureSlug = feature.getSlug();
    this.subscribers.push({feature: featureSlug, callback});
  }

  private loadFeatures(): string[] {
    const enabledFeature = ExperimentalFeatureManager.availableFeatures.filter(
      feature => !!this.storage.get(feature.getSlug())
    );
    return enabledFeature.map(feature => feature.getSlug());
  }

  private saveFeatures(): void {
    ExperimentalFeatureManager.availableFeatures.forEach(feature => {
      this.storage.set(feature.getSlug(), this.enabledFeatures.includes(feature.getSlug()));
    });
  }
}
