import {combineReducers} from 'redux';
import {headerReducer} from 'app/reducers/headerReducer';
import {sessionReducer} from 'app/reducers/sessionReducer';
import {CurrentUserReducer} from 'app/reducers/currentUserReducer';
import {SnackbarReducer} from 'app/components/snackbar/SnackbarReducer';
import {HTTPErrorReducer, HTTPErrorSubrouteReducer} from 'app/components/error/ErrorHandlerReducer';
import {partnerReducer} from 'app/reducers/partnerReducer';
import {HistoryReducer} from 'app/reducers/historyReducer';
import {WebsocketReducer} from 'app/reducers/websocketReducer';
import {SsrLocationReducer} from 'app/reducers/ssrLocationReducer';
import {SkyscraperReducer} from 'app/reducers/skyscraperReducer';
import {PullToRefreshReducer} from 'app/reducers/PullToRefreshReducer';
import {EarlyAccessReducer} from 'app/reducers/earlyAccessReducer';

const rootReducer = () =>
  combineReducers({
    dataHistory: HistoryReducer,
    httpError: HTTPErrorReducer,
    httpErrorSubroute: HTTPErrorSubrouteReducer,
    header: headerReducer,
    session: sessionReducer,
    currentUser: CurrentUserReducer,
    info: SnackbarReducer,
    partner: partnerReducer,
    skyscraper: SkyscraperReducer,
    websocket: WebsocketReducer,
    ssrLocation: SsrLocationReducer,
    pullToRefresh: PullToRefreshReducer,
    earlyAccessFeatures: EarlyAccessReducer,
  });

export default rootReducer;
