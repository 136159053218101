import {useEffect, useState} from 'react';
import {api} from 'app/services/api';

export default function useShowLiveBadge(districtSlug: string | null) {
  const [showLiveBadge, setShowLiveBadge] = useState<boolean>(false);

  useEffect(() => {
    if (!districtSlug) {
      return;
    }

    api
      .get(`/districts/${districtSlug}/live-status`)
      .then(response => {
        setShowLiveBadge(!!response.data?.live);
      })
      .catch(() => {
        setShowLiveBadge(false);
      });
  }, [districtSlug]);

  return showLiveBadge;
}
