export interface featureFlag {
  name: string;
  slug: string;
  availableUntil: Date;
}

const PREFIX = 'feat_';

const featureFlags: featureFlag[] = [
  {
    name: 'Neues Design der Startseite',
    slug: `${PREFIX}district_main_page`,
    availableUntil: new Date('2025-01-31'),
  },
];

export {featureFlags};
