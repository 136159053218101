import React, {useEffect, useRef, useState, Suspense} from 'react';
import {SIZES, COLORS, ELEVATIONS, FONTS, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {LinkOnComponent} from 'app/components/links/link';
import {Back} from 'app/components/appBar/back';
import {
  resetHeader,
  setHeaderAutoScroll,
  toggleHeader,
  updateHeaderKey,
  updateHeaderPreviousPathname,
} from 'app/actions/headerActions';
import NavBar from 'app/components/navBar/NavBar';
import {DesktopLogo} from 'app/components/appBar/DesktopLogo';
import {fetchFuPaUser} from 'app/actions/currentUserActions';
import {selectCurrentUser, selectHydrationFinished} from 'app/hooks/reduxCreateSelectorHooks';
import {createSelector} from 'reselect';
import {AvatarWithBadge} from 'app/components/avatar/AvatarWithBadge';
import {AppBarDesktopWrapper} from 'app/components/appBar/AppBarDesktopStyle';
import {useLocation, useRouteMatch} from 'react-router';
import {ExpandMore} from '@mui/icons-material';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {useEffectAfterMount} from 'app/hooks/dataHooks';
import District from 'app/models/District';
import useEarlyAccessFeatureSelector from 'app/hooks/useEarlyAccessFeatureSelector';

const FollowButton = React.lazy(() => import('app/components/follow/FollowButton'));
const Sidebar = React.lazy(() => import('app/components/sidebar/Sidebar'));

const maxTitleLength = 246;
const titleLengthFactor = 56; // Factor is calculated with the minimal display width value of 320 and its max title length of 180px
const AVATAR_SPACE = 50;

const ChangeRegionTitle = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopNavBar = styled(NavBar)`
  display: none;
  ${props =>
    props.theme.desktopLayout`
      display: flex
   `}
`;

const BackMobile = styled(Back)`
  ${props =>
    props.theme.desktopLayout`
      display: none;
   `}
`;

const Header = styled.div`
  z-index: ${props => props.theme.zIndexShell};
  position: fixed;
  top: 0;
  padding-top: env(safe-area-inset-top);
  transform: ${props => (props.collapsed ? `translateY(-${SIZES['72']})` : 'none')};
  display: flex;
  height: calc(${SIZES['72']} + env(safe-area-inset-top));
  background-color: ${COLORS.fupaPrimary};
  width: 100vw;
  box-sizing: border-box;
  box-shadow: ${ELEVATIONS.AppBar};
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  ${props => props.theme.desktopLayout`
    transform: none;
  `}
`;

const HeaderLink = styled(LinkOnComponent)`
  &&& {
    display: flex;
    height: 1.5rem;
    border-radius: 1rem;
    background-color: rgba(256, 256, 256, 0.15);
    align-items: center;
    padding: 0 0.75rem;
    :focus {
      background-color: ${COLORS.fupaPrimaryFocused};
    }
  }
`;

const RegionSelect = styled(HeaderLink)`
  &&& {
    max-width: 40vw;
    padding-left: 0.75rem;
    padding-right: 0.375rem;
    margin-left: 0.25rem;
  }
`;

const ParentName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  z-index: 3;
  display: flex;
  flex: 1 100%;
  max-width: ${props => props.theme.desktopContentWidth + AVATAR_SPACE}px;
  align-items: flex-end;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  // set height to avoid cls
  min-height: ${SIZES[48]};
  justify-content: flex-end;
  flex: 1 ${titleLengthFactor}%;
  max-width: ${titleLengthFactor}%;
  flex-direction: column;
  white-space: nowrap;
`;

const Title = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: ${FONTS[20]};
  height: 1.625rem;
`;

const LongTitleShrinkFontSize = styled(Typography)`
  &&& {
    color: ${COLORS.white};
    font-size: ${FONTS[16]} !important;
  }
  height: ${SIZES[24]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Breadcrumb = styled.div`
  display: ${props => (props.size === 'small' ? 'none' : 'flex')};
  flex-direction: row;
  height: ${SIZES[24]};
`;

const BreadcrumbHeadline = styled(Typography)`
  &&& {
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: ${COLORS.white};
    font-weight: ${FONTS.bold};
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1 22%;
  padding-right: 0.5rem;
  justify-content: flex-end;
  align-items: center;

  ${props => props.theme.desktopLayout`
    // Needed to display Sidebar correctly
    transform: translateX(0);
  `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const linkMapping = {
  district: (slugs, regionV2Status) => {
    return {link: new District(slugs.districtSlug, regionV2Status).getMainUrl(), follow: true};
  },
  club: slugs => {
    return {link: `/club/${slugs.clubSlug}`, follow: true};
  },
  league: slugs => {
    return slugs.seasonSlug
      ? {link: `/league/${slugs.leagueSlug}/season/${slugs.seasonSlug}`, follow: false}
      : {link: `/league/${slugs.leagueSlug}`, follow: true};
  },
  cup: slugs => {
    return slugs.seasonSlug
      ? {link: `/cup/${slugs.cupSlug}/season/${slugs.seasonSlug}`, follow: false}
      : {link: `/cup/${slugs.cupSlug}`, follow: true};
  },
  team: slugs => {
    return {link: `/team/${slugs.teamSlug}`, follow: false};
  },
  refereeGroup: slugs => {
    return {link: `/referee-group/${slugs.groupSlug}`, follow: false};
  },
  match: slugs => {
    return {link: `/match/${slugs.matchSlug}`, follow: false};
  },
  regionChange: slugs => {
    return slugs?.regionSlug
      ? {link: `/select/${slugs.regionSlug}`, follow: true, allowReferrer: true}
      : {link: '/select', follow: true, allowReferrer: true};
  },
  follows: () => {
    return {link: '/my/following', follow: false};
  },
};

const generateParentLink = ({entity, slugs}, regionV2Status) => {
  if (!entity) {
    return null;
  }
  return linkMapping[entity](slugs, regionV2Status);
};

const selectHeader = createSelector(
  state => state.header,
  header => header
);

const shortenPathname = pathname => {
  return pathname.split('/').splice(0, 3).join('/');
};

const AppBar = ({className, size}) => {
  const [open, setOpen] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const {claims} = useSelector(selectCurrentUser);
  const header = useSelector(selectHeader);
  const hydrationFinished = useSelector(selectHydrationFinished);
  const avatarRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const regionV2Status = useEarlyAccessFeatureSelector('feat_district_main_page');

  // Populate path of caller page (e.g. 'matches') to region select page to return to MatchCalendarPage with new selected district
  const matchedRoute = useRouteMatch({
    path: '/region/:districtSlug/(matches|marketplace|competitions|transfers)?',
    strict: true,
  });
  const referrer = matchedRoute?.params?.[0];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setPrevScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    dispatch(updateHeaderKey({key: location.key}));
  }, [location.key]);

  useEffect(() => {
    const pathname = shortenPathname(location.pathname);
    dispatch(updateHeaderPreviousPathname({pathname}));
  }, [location.pathname]);

  useEffectAfterMount(() => {
    const pathname = shortenPathname(location.pathname);
    if (header.previousPathname !== pathname) {
      dispatch(resetHeader({pathname}));
    }
  }, [location.pathname]);

  const handleScroll = e => {
    const window = e.currentTarget;
    if (header.autoScroll) {
      dispatch(setHeaderAutoScroll(false));
      return;
    }
    if ((prevScrollPosition > window.scrollY + 16 || window.scrollY === 0) && header.collapsed) {
      dispatch(toggleHeader(false));
    } else if (prevScrollPosition < window.scrollY - 16 && !header.collapsed) {
      dispatch(toggleHeader(true));
    }
    setPrevScrollPosition(window.scrollY);
  };

  const handleSidebar = event => {
    if (event && avatarRef.current?.contains(event.nativeEvent.srcElement)) {
      // Check if handleSidebar is triggered by click event on anchor element with open drawer panel
      // Prevent duplicate execution of state change
      return;
    }
    if (!open && claims.u && claims.email_verified) {
      dispatch(fetchFuPaUser());
    }
    setOpen(!open);
  };

  let parent = '';
  if (header.parent) {
    if (header.parent.entity && header.parent.entity !== 'none') {
      const {link, follow, allowReferrer} = generateParentLink(header.parent, regionV2Status);
      const name =
        header.parent.entity === 'regionChange' && header.parent.name !== 'Keine Region gewählt' ? (
          <ChangeRegionTitle>
            <BreadcrumbHeadline variant='caption1' component={header.parent.variant}>
              Deine Region:
              <RegionSelect
                to={{pathname: link, state: allowReferrer && referrer ? {referrer} : null}}
                rel={follow ? 'follow' : 'nofollow'}>
                <ParentName>{header.parent.name}</ParentName>
                <ExpandMore fontSize='small' />
              </RegionSelect>
            </BreadcrumbHeadline>
          </ChangeRegionTitle>
        ) : (
          <HeaderLink
            to={{pathname: link, state: allowReferrer && referrer ? {referrer} : null}}
            rel={follow ? 'follow' : 'nofollow'}>
            <BreadcrumbHeadline variant='caption1' component={header.parent.variant}>
              {header.parent.name}
            </BreadcrumbHeadline>
          </HeaderLink>
        );
      parent = <Breadcrumb size={size}>{name}</Breadcrumb>;
    } else {
      parent = (
        <Breadcrumb size={size}>
          <BreadcrumbHeadline variant='caption1' component={header.parent.variant}>
            {header.parent.name}
          </BreadcrumbHeadline>
        </Breadcrumb>
      );
    }
  }

  let season = '';
  if (header.season) {
    season = (
      <Breadcrumb size={size}>
        <BreadcrumbHeadline variant='caption1' component={header.parent.variant}>
          {header.season}
        </BreadcrumbHeadline>
      </Breadcrumb>
    );
  }

  let title = (
    <Title component={header.variant} variant='title1'>
      {header.title}
    </Title>
  );
  let titleWidth = (320 * titleLengthFactor) / 100;
  if (header.title) {
    if (windowIsDefined) {
      titleWidth = (window.innerWidth * titleLengthFactor) / 100;
    }

    let titleChars = header.title.length;
    let quot = titleWidth / titleChars;

    if (quot < 11 || titleWidth > maxTitleLength) {
      title = (
        <LongTitleShrinkFontSize component={header.variant} variant='subheading'>
          {header.title}
        </LongTitleShrinkFontSize>
      );
    }
  }

  let followMobil = null;
  let followDesktop = null;
  if (header.follow) {
    followMobil = hydrationFinished ? (
      <Suspense fallback={<div />}>
        <FollowButton adjustStyles='mobile' />
      </Suspense>
    ) : (
      <div />
    );
    followDesktop = hydrationFinished ? (
      <Suspense fallback={<div />}>
        <FollowButton adjustStyles='desktop' isDesktop={true} />
      </Suspense>
    ) : (
      <div />
    );
  }

  const avatarSrc = claims?.picture ?? null;
  const userName = `Nutzeroptionen${claims?.name ? ` von ${claims.name}` : ''} öffnen.`;

  const sidebar = hydrationFinished ? (
    <Suspense fallback={<div />}>
      <Sidebar anchorEl={avatarRef} open={open} onClose={handleSidebar} />
    </Suspense>
  ) : (
    <div />
  );

  return (
    <Header id='fupa-appbar' size={header.size} collapsed={header.collapsed} className={className}>
      <AppBarDesktopWrapper>
        <Container>
          <DesktopLogo />
          <BackMobile color={COLORS.white} />
          <TitleContainer>
            {parent}
            <Wrapper>
              {title}
              {season}
              {followDesktop}
            </Wrapper>
          </TitleContainer>
          <Actions>
            {followMobil}
            <DesktopNavBar idSuffix='desktop' />
            <div>
              <AvatarWithBadge
                ref={avatarRef}
                active={open}
                onClick={handleSidebar}
                width={32}
                src={avatarSrc}
                alt={userName}
              />
              {sidebar}
            </div>
          </Actions>
        </Container>
      </AppBarDesktopWrapper>
    </Header>
  );
};

export {AppBar};
