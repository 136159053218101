import {DefaultRootState, useSelector} from 'react-redux';
import {createSelector} from 'reselect';

declare module 'react-redux' {
  interface DefaultRootState {
    earlyAccessFeatures: Record<string, string>;
  }
}

export enum EarlyAccessFeatureStatus {
  PENDING = 'pending',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

const selectEarlyAccessFeature = createSelector(
  [(state: DefaultRootState) => state.earlyAccessFeatures, (_, feature?: string) => feature],
  (earlyAccessFeatures, feature) => {
    if (!feature) {
      return earlyAccessFeatures;
    } else {
      return earlyAccessFeatures[feature]
        ? (earlyAccessFeatures[feature] as EarlyAccessFeatureStatus)
        : EarlyAccessFeatureStatus.PENDING;
    }
  }
);

export default function useEarlyAccessFeatureSelector(feature: string): EarlyAccessFeatureStatus;
export default function useEarlyAccessFeatureSelector(): Record<string, string>;
export default function useEarlyAccessFeatureSelector(
  feature?: string
): EarlyAccessFeatureStatus | Record<string, string> {
  return useSelector(state => selectEarlyAccessFeature(state, feature));
}
