import {AD_SLOT_HEIGHT} from 'app/components/ads/adHelpers';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {useEffect} from 'react';
import {IS_CHROME, PARALLAX_SIZES, PARALLAX_SLOT_IDS} from 'app/components/ads/ad.constants';

const HEADER_TABBAR_HEIGHT = 114;
const NAVBAR_HEIGHT = 64;

let lastScrollPos = 0;

const addClass = (el, cl) => {
  const regex = new RegExp('(?:^|\\s)' + cl + '(?!S)', 'g');
  if (!el.className.match(regex)) {
    el.className += ' ' + cl;
  }
};

const createCss = (element, style) => {
  for (const property in style) {
    element.style[property] = style[property];
  }
};

const createStyleTag = () => {
  const parallaxStyleTag = document.getElementById('fupa-parallax-style');
  if (!parallaxStyleTag) {
    const css = `@supports not (clip-path: inset(0px)) {.fupa-parallax {position: absolute;}}`,
      head = document.head,
      style = document.createElement('style');
    head.appendChild(style);
    style.id = 'fupa-parallax-style';
    style.appendChild(document.createTextNode(css));
  }
};

const updatePosition = () => {
  let currentScrollPos = window.pageYOffset || window.scrollY;
  const nodes = document.getElementsByClassName('fupa-parallax');
  const array = [...nodes];
  array.map(adContainer => {
    const adServerDiv = adContainer.firstChild;
    const innerHeight = window.innerHeight;

    const adContainerBoundingRect = adContainer.getBoundingClientRect();
    const adContainerPos = adContainerBoundingRect.top;
    const scrollAd_creativeHeight = adContainerBoundingRect.height;

    if (adServerDiv) {
      const adServerDivBoundingRect = adServerDiv.getBoundingClientRect();
      const adServerDivPos = adServerDivBoundingRect.top;
      const creativeHeight = adServerDivBoundingRect.height;

      // Scroll up
      if (lastScrollPos > currentScrollPos) {
        const adServerDivPosBottom = adServerDivPos + creativeHeight;
        const adContainerPosBottom = adContainerPos + scrollAd_creativeHeight;
        if (adContainerPosBottom >= adServerDivPosBottom) {
          const margin = innerHeight - NAVBAR_HEIGHT - adContainerPosBottom + 'px';
          adServerDiv.style.transform = `translateY(-${margin})`;
          adServerDiv.style.webkitTransform = `translateY(-${margin})`;
        } else if (
          adServerDivPos < HEADER_TABBAR_HEIGHT &&
          adContainerPos + scrollAd_creativeHeight < HEADER_TABBAR_HEIGHT
        ) {
          // Creative is out of viewport (top) - user has scrolled up - and now user starts to scroll up again
          // Creative comes into viewport from top
          const margin = innerHeight - HEADER_TABBAR_HEIGHT - creativeHeight - NAVBAR_HEIGHT + 'px';
          adServerDiv.style.transform = `translateY(-${margin})`;
          adServerDiv.style.webkitTransform = `translateY(-${margin})`;
        }
      } else if (lastScrollPos < currentScrollPos) {
        // Scroll down
        const threshold = innerHeight - NAVBAR_HEIGHT - creativeHeight;
        if (adContainerPos > threshold) {
          // Creative is out of viewport (bottom) - user starts to scroll down
          // Creative comes into viewport from bottom
          adServerDiv.style.transform = `translateY(0)`;
          adServerDiv.style.webkitTransform = `translateY(0)`;
        } else if (adContainerPos < threshold) {
          // Creative is completed shown in viewport - sticky
          const margin = threshold - adContainerPos + 'px';
          adServerDiv.style.transform = `translateY(-${margin})`;
          adServerDiv.style.webkitTransform = `translateY(-${margin})`;
        }
      }
    }
  });

  lastScrollPos = currentScrollPos;
};

const initParallax = (id, size) => {
  const [scrollAd_creativeWidth, scrollAd_creativeHeight] = size;
  const scrollAd_pannelHeight = AD_SLOT_HEIGHT;
  // Set maxHeight for ad div to display inventory correctly for slot expansion (partial screen)
  const maxCreativeHeight = 600;
  const adContainer = document.getElementById(id); // <Core id='ad-Rectangle_1'>
  const adServerDiv = adContainer?.firstChild;
  // Prevent FuPa-Parallax if Creative has already Parallax-Effekt by QM-Template
  if (/scrollAdHeight_/.test(adContainer.className) || !adServerDiv) {
    return;
  }

  addClass(adContainer, 'fupa-parallax');

  createStyleTag();

  createCss(adContainer, {
    height: `${scrollAd_pannelHeight}px`,
    'clip-path': 'inset(0px)',
    '-webkit-clip-path': 'inset(0px)',
    clip: 'rect(auto auto auto auto)',
  });

  createCss(adServerDiv, {
    position: 'fixed',
    width: `${scrollAd_creativeWidth}px`,
    height: `${scrollAd_creativeHeight}px`,
    bottom: `${NAVBAR_HEIGHT}px`,
    margin: 'auto',
    'transform-origin': 'bottom',
    '-webkit-transform-origin': 'bottom',
    'transition-duration': '0',
    '-webkit-transition-duration': '0',
  });

  createCss(adContainer.parentNode, {
    height: `${scrollAd_pannelHeight}px`,
    'max-height': `${maxCreativeHeight}px`,
    width: '100%',
    position: 'relative',
  });

  // Note: EventListener gets not registered again if its already exists
  // No need to check if already registered by another parallax ad
  document.addEventListener('scroll', updatePosition);
};

const destroyParallax = id => {
  const adContainer = document.getElementById(id);
  if (adContainer) {
    // Use Case: Container gets refreshed
    removeParallaxStyle(adContainer);
  } else {
    // Use Case: route changes, all slots get destroyed
    document.removeEventListener('scroll', updatePosition);
  }
};

const removeParallaxStyle = element => {
  const hasParallax = element.classList.contains('fupa-parallax');
  const parallaxStyleTag = document.getElementById('fupa-parallax-style');
  if (parallaxStyleTag) {
    parallaxStyleTag.remove();
  }
  if (hasParallax) {
    element.removeAttribute('style');
    element.classList.remove('fupa-parallax');
    element.parentElement.removeAttribute('style');
    element.firstChild?.removeAttribute('style');
  }
};

const useParallax = () => {
  const isDesktop = useIsDesktop();
  const deviceType = isDesktop ? 'desktop' : 'mobile';
  useEffect(() => {
    let lastAdServerAdUnitCode;
    window.pbjs.que.push(() => {
      window.pbjs.onEvent('adRenderSucceeded', () => {
        const prebidSlots = window.pbjs.getAllWinningBids();
        const prebidSlotForAdUnit = prebidSlots.reverse().find(prebidSlot => {
          return prebidSlot.adUnitCode === lastAdServerAdUnitCode;
        });
        if (prebidSlotForAdUnit) {
          // Disable Parallax for Chrome on Mobile because of wrong viewability measurement
          if (
            !IS_CHROME &&
            deviceType === 'mobile' &&
            PARALLAX_SLOT_IDS.includes(lastAdServerAdUnitCode) &&
            PARALLAX_SIZES.includes(`${prebidSlotForAdUnit.width}x${prebidSlotForAdUnit.height}`)
          ) {
            const creativeSize = [prebidSlotForAdUnit.width, prebidSlotForAdUnit.height];
            initParallax(lastAdServerAdUnitCode, creativeSize);
          } else if (PARALLAX_SLOT_IDS.includes(lastAdServerAdUnitCode)) {
            destroyParallax(lastAdServerAdUnitCode);
          }
        }
      });
    });

    window.googletag.cmd.push(() => {
      window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        const slot = event.slot;
        const slotId = slot.getSlotElementId();
        lastAdServerAdUnitCode = slotId;
        const slotSize = event.size?.map(size => size).join('x');
        const prebidSlot = window.pbjs?.getAdserverTargetingForAdUnitCode?.([slotId]);

        const bidderWithParallax = ['sod', 'sodfupa', 'justpremium'];
        // Expection for SoD Slider and Justpremium on Rectangle_1
        // Additional check to remove fix height from <AdSlot> to show creative and content correctly
        if (slotId === 'ad-Rectangle_1' && bidderWithParallax.includes(prebidSlot?.hb_bidder)) {
          // set styles
          const adContainer = document.getElementById(slotId);
          if (adContainer) {
            adContainer.parentNode.style.height = 'auto';
            adContainer.parentNode.style.minHeight = `${AD_SLOT_HEIGHT}px`;
          }
          return;
        }

        if (
          !IS_CHROME &&
          deviceType === 'mobile' &&
          PARALLAX_SLOT_IDS.includes(slotId) &&
          PARALLAX_SIZES.includes(slotSize)
        ) {
          initParallax(slotId, event.size);
        } else if (PARALLAX_SLOT_IDS.includes(slotId)) {
          destroyParallax(slotId);
        }
      });
    });
  }, []);
};

export {useParallax, destroyParallax};
