import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {FuPaPlayer} from 'app/components/icons';

const Wrapper = styled.a`
  display: none;
  text-decoration: none;
  ${props => props.theme.desktopLayout`
    display: flex;
    margin-right: 1rem;
    margin-left: 1.5rem;
    padding-bottom: 0.25rem;
  `}
`;

const StyledFuPaPlayer = styled(FuPaPlayer)`
  path:last-child {
    fill: ${COLORS.white};
  }
`;

const DesktopLogo = () => {
  return (
    <Wrapper href={'/'}>
      <StyledFuPaPlayer style={{color: COLORS.fupaSecondary, fontSize: 36}} />
    </Wrapper>
  );
};

export {DesktopLogo};
