import {useEffect, useState} from 'react';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';

export function useLocalStorage(key: string, initialValue: any) {
  const [value, setValue] = useState(() => LocalStorageManager.getInstance().getItem(key) ?? initialValue);
  useEffect(() => {
    const storageChange = event => {
      if (event.detail?.key === key) {
        setValue(event.detail.value);
      }
    };
    window.addEventListener('storageChanged', storageChange);
    return () => window.removeEventListener('storageChanged', storageChange);
  }, [key]);

  return value;
}
